import request from '@/utils/request'

// 详情接口
export function getInfo(params) {
  return request({
    url: 'user/getInfo',
    method: 'post',
    params
  })
}
// 考期详情接口
export function periodGetInfo(params) {
  return request({
    url: 'period/getInfo',
    method: 'post',
    params
  })
}


// 报名
export function studentCreate(data) {
  return request({
    url: 'student/submit',
    method: 'post',
    data
  })
}

// 成绩查询
export function queryScore(data) {
  return request({
    url: 'student/queryScore',
    method: 'post',
    params: data
  })
}

// 成绩查询
export function systemDicts(data) {
  return request({
    url: 'domain/systemDicts',
    method: 'post',
    params: data
  })
}

export function userDetail(data) {
  return request({
    url: 'student/getPeriodInfo',
    method: 'post',
    params: data
  })
}
export function domainConvert(data) {
  return request({
    url: 'domain/convert',
    method: 'post',
    params: data
  })
}

export function studentInfo(data) {
  return request({
    url: '/student/getInfo',
    method: 'post',
    params: data
  })
}

// 文件上传
export function fileUpload(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data: data,
    timeout: 60000
  })
}

// 文件查询
export function fileQuery(dictType) {
  return request({
    url: '/file/preview' + dictType,
    method: 'get'
  })
}