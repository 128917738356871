import axios from 'axios'
import errorCode from '@/utils/errorCode'
import { Toast  } from 'vant'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : window.location.origin, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // 是否需要设置 token
    config.headers['terminal'] = 'app'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.resultCode || '000000' 
    // 获取错误信息
    const msg = errorCode[code] || res.data.resultMsg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res
    }
    if (code === 9999) { // 账号已登录
      return res.data
    }
    if (code === 401) {
      Toast({
        position: 'top',
        message: res.data.msg
      })
      // return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
      return
    } else if (code === 500) {
      Toast({
        position: 'top',
        message: msg
      })
      return Promise.reject(new Error(msg))
    } else if (code !== '000000') {
      Toast({
        position: 'top',
        message: msg
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    Toast({
      position: 'top',
      message: error.message
    })
    return Promise.reject(error)
  }
)

export default service
